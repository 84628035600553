import React from 'react'
import { graphql } from 'gatsby'
import { getRecentPostsFromEdges } from '../../utils/utilFunctions'
import { Container, Row, Col } from 'reactstrap'
import SEO from "../../components/seo"
import Layout from "../../containers/layout/layout"
import Header from '../../containers/layout/header'
import Footer from '../../containers/layout/footer'
import Text from '../../components/shared/text'
import Heading from '../../components/shared/heading'
import Blog from '../../components/blog/layout-two'
import SearchWidget from '../../containers/widgets/search'
import RecentPostWidget from '../../containers/widgets/recent-post'
import CategoryWidget from '../../containers/widgets/categories'
import { SectionWrap, PageHeader, BlogListWrap, SidebarWrap } from './search-template.stc'

const SearchTemplate = ({ data, pageContext, location, ...restProps }) => {
    const [author] = data.allContentfulPerson.edges;
    const filesystemPosts = [];
    const { headingCSS, textCSS, blogStyle } = restProps;
    const { state } = location;
    const query = state && state.query;
    const { categoriesSummary } = pageContext;

    let posts = data.allContentfulBlogPost.edges.map( ({ node }) => {
        const dateSplit = node.date.split(" ");
        const {text: quote_text, author: quote_author} = node.quote !== null ? node.quote : { };

        return {
            node: {
                fields: {
                    slug: node.slug,
                    collection: 'contentfulPosts',
                    dateSlug: node.dateSlug
                },
                frontmatter: {
                    sortKey: node.sortKey,
                    date: node.date,
                    format: node.format,
                    title: node.title,
                    video_link: node.video_link,
                    quote_text: quote_text,
                    quote_author: quote_author,
                    link: node.link,
                    author: {
                        name: node.author.name,
                    },
                    image: node.image,
                    images: node.images,
                    category: node.category,
                },
                excerpt: node.body.childMarkdownRemark.excerpt,
                html: node.body.body,
            }
        }
    });

    const recentFilesystemPosts = [];
    const recentContentfulPosts = data.recentContentfulBlogPost.edges;
    const recentPosts = getRecentPostsFromEdges(recentFilesystemPosts, recentContentfulPosts);

    let filteredBlogs = [];
    filteredBlogs = query && posts.filter(post => {
        const { title, quote_text, quote_author, category, tags, author: { name: authorName } } = post.node.frontmatter
        const { html } = post.node
        return (
            (title && title.toLowerCase().includes(query)) ||
            (quote_text && quote_text.toLowerCase().includes(query)) ||
            (quote_author && quote_author.toLowerCase().includes(query)) ||
            (html && html.toLowerCase().includes(query)) ||
            (authorName && authorName.toLowerCase().includes(query)) ||
            (category && category.toLowerCase().includes(query)) ||
            (tags &&
                tags
                    .join("")
                    .toLowerCase()
                    .includes(query.toLowerCase()))
        )
    })

    return (
        <Layout>
            <SEO 
                title={`Search For: ${query ? query : ''}`} 
                description={`Search Results for ${query}`}
                keywords={['search', `${query}`]}
            />
            <Header authorData={author.node}/>
            <div className="main-content">
                <SectionWrap>
                    <Container>
                        <Row>
                            <Col xl={8}>
                                <PageHeader>
                                    <Text {...textCSS}>Search Results for "{query}"</Text>
                                    <Heading {...headingCSS}>Post found: {filteredBlogs ? filteredBlogs.length : 0}</Heading>
                                </PageHeader>
                                <BlogListWrap>
                                    {filteredBlogs && filteredBlogs.map(blog => (
                                        <Blog
                                            {...blogStyle}
                                            key={blog.node.fields.slug}
                                            content={{
                                                ...blog.node.fields,
                                                ...blog.node.frontmatter,
                                                excerpt: blog.node.excerpt
                                            }}
                                        />
                                    ))}
                                </BlogListWrap>
                            </Col>
                            <Col xl={4}>
                                <SidebarWrap>
                                    <SearchWidget />
                                    <RecentPostWidget recentPosts={recentPosts}/>
                                    <CategoryWidget categoriesSummary={categoriesSummary}/>
                                </SidebarWrap>
                            </Col>
                        </Row>
                    </Container>
                </SectionWrap>
            </div>
            <Footer />
        </Layout>
    )
}

export const query = graphql`
    query BlogBySearchQuery{
        recentContentfulBlogPost: allContentfulBlogPost(
            filter: { format : { eq: "image" } }
            sort: {order: DESC, fields: publishDate}
            limit: 4
        ) {
            edges {
                node {
                    ...RecentContentfulBlogPostField
                }
            }
        }        
        allContentfulBlogPost(
            sort: {order: DESC, fields: publishDate}
        ) {
            totalCount
            edges {
                node {
                    ...MajorContentfulBlogPostField
                    author {
                        name
                    }
                    body {
                        body
                        childMarkdownRemark {
                            excerpt(format: PLAIN, pruneLength: 165)
                        }
                   }
                }
            }
        }
        allContentfulPerson(filter: { id: { eq: "801d0181-aa93-5da9-82f1-fae3b71554af" } }) {
            edges {
                node {
                    ...ContentfulPersonField
                }
            }
        }
    }
`;

SearchTemplate.defaultProps = {
    textCSS: {
        mb: '11px',
        mt: '-4px'
    },
    headingCSS: {
        as: 'h4',
        color: 'secondaryColor'
    },
    blogStyle: {
        mb: '30px'
    },
    categoriesSummary: {},
}

export default SearchTemplate
