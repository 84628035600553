import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import WidgetBox, {WidgetTitle} from '../../../components/shared/widget-box'
import Blog from '../../../components/blog/layout-three'
import {RecentPostWrap, RecentPostItem} from './recent-post.stc'

const RecentPost = ({recentPosts}) => {
    const blogs = recentPosts;
    return (
        <WidgetBox>
            <WidgetTitle>Recent Posts</WidgetTitle>
            <RecentPostWrap>
                {blogs.map(blog => (
                    <RecentPostItem key={blog.node.fields.slug}>
                        <Blog
                            content={{
                                ...blog.node.fields, 
                                ...blog.node.frontmatter
                            }}
                        />
                    </RecentPostItem>
                ))}
            </RecentPostWrap>
        </WidgetBox>
    )
}

RecentPost.defaultProps = {
    recentPosts: [],
}
 
export default RecentPost;
