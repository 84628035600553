import React from 'react'
import {slugify} from '../../../utils/utilFunctions'
import WidgetBox, {WidgetTitle} from '../../../components/shared/widget-box'
import Anchor from '../../../components/shared/anchor'
import {CategroiesWrap, CategroiesItem} from './categories.stc'

const Categroies = ({categoriesSummary}) => {
    var catObj = categoriesSummary;

    let categroyItem = [];
    for (const prop in catObj) {
        categroyItem.push(<CategroiesItem key={`${prop}-${catObj[prop]}`}>
            <Anchor path={`/category/${slugify(prop)}`}>
                <span className="text">{prop}</span> 
                <span className="number">{catObj[prop]}</span>
            </Anchor>
        </CategroiesItem>);
    }
    
    return (
        <WidgetBox>
            <WidgetTitle>Categories</WidgetTitle>
            <CategroiesWrap>
                {categroyItem}
            </CategroiesWrap>
        </WidgetBox>
    )
}

Categroies.defaultProps = {
    categoriesSummary: {},
};

export default Categroies
